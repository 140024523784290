import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import lottie from 'lottie-web'
// import scrollInto from 'smooth-scroll-into-view-if-needed'
import { Footer } from '~src/components/Footer'
import { HeaderPlaceHolder } from '~src/components/HeaderPlaceHolder'
import { Button } from '~src/units/Button'
import { Download } from '~src/units/Download'
import bg from '~src/assets/image/bg.svg'
import bg2 from '~src/assets/image/bg2.svg'
import step1 from '~src/assets/sections/step1.svg'
import step2 from '~src/assets/sections/step2.svg'
import step3 from '~src/assets/sections/step3.svg'
import s1p1 from '~src/assets/sections/s1p1.svg'
import s2p1 from '~src/assets/sections/s2p1.svg'
// import s2p2 from '~src/assets/sections/s2p2.svg'
import s2p3 from '~src/assets/sections/s2p3.svg'
import s3p1 from '~src/assets/sections/s3p1.svg'
import s3p2 from '~src/assets/sections/s3p2.svg'
import s3p3 from '~src/assets/sections/s3p3.svg'
import s4p1 from '~src/assets/sections/s4p1.svg'
import s4p2 from '~src/assets/sections/s4p2.svg'
import s4p3 from '~src/assets/sections/s4p3.svg'
import s4p4 from '~src/assets/sections/s4p4.svg'
import s5p1 from '~src/assets/sections/s5p1.svg'
import PHIcon from '~src/assets/svg/ph-widget.svg'

import {
  primaryColor,
  defaultColor,
  descriptionColor,
  strongColor,
  sectionInlineSize,
  smBreakPoint,
  xsBreakPoint,
  mdBreakPoint,
  lgBreakPoint,
} from '~src/common/cssTips'
import sendGaForTryNow from '~src/utils/sendGaForTryNow'
import { getZhCNtranscript, isZhCN } from '~src/utils/locale'
import { mixpanel } from '~src/marketing/mixpanel'
import { getShareInfo } from '~src/utils/fromShare'
import bonjourLottie from '~src/data/bonjour-lottie.json'
import bonjourS2Lottie from '~src/data/bonjour-s2-lottie.json'
import bonjourS4Lottie from '~src/data/bonjour-s4-lottie.json'


const transcript = {
  mainHeading: getZhCNtranscript(
    '与 Elisi 一起打造结构化人生',
    'Structure Your Dreams into Reality with Elisi'
  ),
  freeDownload: getZhCNtranscript('免费下载 Elisi', 'Download Elisi For Free'),
  tryItOut: getZhCNtranscript('直接开始', 'Try it out'),
  description0: getZhCNtranscript(
    '高效能自主层级式计划助手',
    'Aim high, plan smart, excel with Elisi.'
  ),

  subHeading1: getZhCNtranscript(
    '不知所措，缺乏动力，还是对自己的道路不确定？',
    'Overwhelmed, Lacking Motivation, or Unsure of Your Path?'
  ),
  description1: getZhCNtranscript(
    '驾驭生活和工作的复杂性。找到清晰的答案，并与 Elisi 一起负责，Elisi 是你实现个人成长的终极组织者和指南。',
    'Navigate both work and life\'s complexities. Find clarity and take charge with Elisi, your ultimate organizer and guide for personal achievement.'
  ),

  subHeading2: getZhCNtranscript(
    '与 Elisi 一起规划成功之路',
    'Charting the Course to Success with Elisi'
  ),
  description2: getZhCNtranscript(
    'Elisi 为你描绘了从梦想到现实的道路。',
    'Elisi charts your path from dreams to reality.'
  ),

  subHeading3: getZhCNtranscript(
    '无论你在哪里',
    'Begin Wherever You Are'
  ),
  description3: getZhCNtranscript(
    '今天就是实现目标的第一天',
    'Today is the perfect day to start pursuing your goals.'
  ),

  subHeading4: getZhCNtranscript(
    '极简设计，适合现代工作方式',
    'AI-Powered Assistance That Fits Your Way of Working'
  ),
  description4: getZhCNtranscript(
    '为您和您的梦想量身定制。',
    'An AI with you and your goals in mind.'
  ),
  subTitle41: getZhCNtranscript(
    'Elisi 是否保护我的数据？',
    'Does Elisi Protect My Data?'
  ),
  subDescription41: getZhCNtranscript(
    '放心使用 Elisi，我们绝不会与任何第三方分享您的数据。',
    'Use Elisi with full confidence, knowing that we never share your data with third parties. '
  ),
  subTitle42: getZhCNtranscript(
    'Elisi AI 如何精简我的工作？',
    'How Does Elisi AI Help Streamline My Work?'
  ),
  subDescription42: getZhCNtranscript(
    'Elisi AI 旨在理解并适应您的个人需求，确保每一天都更有条理和高效。',
    'Elisi AI adapts to your personal needs, ensuring each day is more organized and productive.'
  ),
  subTitle43: getZhCNtranscript(
    'Elisi 的费用是多少？',
    'How Much Does Elisi Cost?'
  ),
  subDescription43: getZhCNtranscript(
    '拥抱变化，毫不费力。Elisi 致力于提供便捷服务，主要功能套件完全免费。',
    'Elisi is committed to accessibility, offering its major suite of features completely free of charge. '
  ),
  subTitle44: getZhCNtranscript(
    '用 Elisi 探索你的可能性',
    'Explore your Possibilities with Elisi'
  ),
  subDescription44: getZhCNtranscript(
    '亲身体验 Elisi 的创新功能如何为您的日常生活带来效率和秩序。它在所有设备上同步，更容易查看您的进度并保持您的动力。',
    'Experience how Elisi\'s innovative features bring efficiency and order to your routine. Elisi syncs across all your devices, making it easier to see your progress and maintain your momentum.'
  ),

  subHeading5: getZhCNtranscript(
    '带着明确的目标行动',
    'Act with Greater Purpose and Clarity'
  ),
  description5: getZhCNtranscript(
    '每一次行动都塑造着你的未来。与超过 150,000 名的 Elisi 用户携手，朝着梦想迈出有意义的一步。',
    'With Elisi, each action shapes your future. Join over 150,000 users making impactful steps towards their dreams.'
  ),

  subHeading6: getZhCNtranscript(
    'Elisi：赋能你的梦想',
    'Elisi: Elevate Your Dreams.'
  ),
  description6: getZhCNtranscript(
    '获取赋予您进步力量的应用程序。',
    'Get the app that empowers your progress.'
  ),
} as const

const Wrapper = styled.div`
`

const Section = styled.section<{ short?: boolean }>`
  margin-bottom: 130px;

  ${(props) =>
    props.short
      ? css`
          margin-bottom: 35px;
        `
      : ''}

  @media screen and (max-width: ${smBreakPoint}) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 100px;
  }
  @media screen and (max-width: ${xsBreakPoint}) {
    margin-bottom: 60px;
  }
`

const BonjourBackground = styled.div`
  flex-grow: 1;
  transform: scale(0.9);
`;

const BonjourS2Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(5px, 2px) scale(1.5);
`;

const BonjourS4Background = styled.div`
  position: absolute;
  top: -3px;
  left: 56px;
  right: -56px;
  bottom: 0;
`;

const HomeSection = styled.div`
  ${sectionInlineSize};
  height: 600px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -70px bottom;
  margin-bottom: 80px;
  display: flex;

  @media screen and (max-width: ${lgBreakPoint}) {
    height: 500px;
    background-size: 85% !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100px center;
  }
  @media screen and (max-width: ${mdBreakPoint}) {
    height: 420px;
    background-size: 80% !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100px top;
  }
  @media screen and (max-width: ${smBreakPoint}) {
    height: 720px;
    padding: 0;
    margin-bottom: 80px;
    background-size: 100%, 100% !important;
    background-image: url(${bg}), url(${bg2});
    background-repeat: no-repeat;
    background-position: -20vw bottom, center top;
    display: flex;
    flex-direction: column;
    align-content: center;

    ${BonjourBackground} {
      transform: scale(0.5) translate(25%, -50%);
    }
  }
  @media screen and (max-width: 600px) {
    height: 705px;
  }
  @media screen and (max-width: 540px) {
    height: 675px;
  }
  @media screen and (max-width: ${xsBreakPoint}) {
    height: 575px;
    padding: 0;
    margin-bottom: 20px;
    background-size: 100%, 100% !important;
    background-image: url(${bg}), url(${bg2});
    background-repeat: no-repeat;

    margin-bottom: 90px;
  }

  @media screen and (max-width: 440px) {
    height: 565px;
  }
  @media screen and (max-width: 400px) {
    height: 555px;
  }
  @media screen and (max-width: 380px) {
    height: 545px;
  }
`

const TryLinksWrapper = styled.div`
  margin-bottom: 20px;
  @media screen and (max-width: ${xsBreakPoint}) {
    margin: 0 0 4vh 0;
  }
`
const TryLinksWrapper2 = styled.div`
  ${Button} {
    min-width: 10em;
  }

  @media screen and (max-width: ${smBreakPoint}) {
    margin-right: 0 !important;
  }

  @media screen and (max-width: ${xsBreakPoint}) {
    margin-bottom: 10px !important;
    width: 100%;
    text-align: center;
    button,
    a {
      width: 85%;
      margin-right: 0 !important;
    }
  }
`

const BonjourSection = styled.div`
  padding-top: 130px;
  padding-left: 60px;
  width: 600px;
  box-sizing: border-box;
  flex-shrink: 0;

  ${TryLinksWrapper} {
    width: 100%;
    button,
    a {
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media screen and (max-width: ${lgBreakPoint}) {
    padding-top: 100px;
    padding-left: 40px;
    width: 600px;
  }
  @media screen and (max-width: ${mdBreakPoint}) {
    padding-top: 20px;
    padding-left: 30px;
    width: 500px;
  }
  @media screen and (max-width: ${smBreakPoint}) {
    padding-top: 35px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    text-align: center;

    ${TryLinksWrapper} {
      width: 100%;
      text-align: center;
      button,
      a {
        font-size: 14px;
        width: 85%;
        margin: 0;
        margin-bottom: 20px;
        height: 48px;
        line-height: 32px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  @media screen and (max-width: ${xsBreakPoint}) {
    padding-top: 35px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    text-align: center;

    ${TryLinksWrapper} {
      width: 100%;
      text-align: center;
      button,
      a {
        width: 85%;
        margin: 0;
        margin-bottom: 20px;
        height: 48px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
`

const MainHeading = styled.h1`
  min-height: 1em;
  margin: 0 0 3vh 0;
  font-size: 54px;
  line-height: 1.2;
  font-weight: bold;
  color: ${defaultColor};
  letter-spacing: 0;

  @media screen and (max-width: ${xsBreakPoint}) {
    margin: 0 0 15px 0;
    font-size: 32px;
    text-align: center;
  }
`

const SubHeading = styled.h2`
  font-size: 36px;
  line-height: 50px;
  font-weight: 500;
  color: ${defaultColor};
  text-align: center;
  margin-bottom: 19px;

  @media screen and (max-width: ${smBreakPoint}) {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center !important;
  }

  @media screen and (max-width: ${xsBreakPoint}) {
    margin: 0 0 15px 0;
    font-size: 23px;
  }
`

const Description = styled.h3`
  line-height: 28px;
  margin: 0 0 40px 0;
  font-size: 20px;
  font-weight: 500;
  color: ${descriptionColor};
  text-align: center;
  min-height: 1em;
  &.left {
    text-align: left;
  }

  @media screen and (max-width: ${smBreakPoint}) {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center !important;
  }

  @media screen and (max-width: ${xsBreakPoint}) {
    min-height: 0;
    margin: 0 0 35px 0;
    font-size: 14px;
    letter-spacing: 0px;
  }
`

const Step = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;

  img {
    width: 58px;
    height: 58px;
  }

  &::after {
    display: block;
    content: '';
    width: 100%;
    border-bottom: 2px dashed ${primaryColor};
  }

  &.widthLimit {
    &::after {
      /** 295 + 58 */
      max-width: 237px;
    }
  }

  @media screen and (max-width: ${smBreakPoint}) {
    justify-content: center;
    height: 43px;
    margin-top: 20px;

    img {
      width: 43px;
      height: 43px;
    }

    &::after {
      content: unset;
    }
  }
`

const SubTitle = styled.h3`
  font-size: 22px;
  font-weight: bold;
  color: ${strongColor};
  line-height: 30px;
  position: relative;
  margin: 40px 0 18px;

  &.widthLimit {
    max-width: 295px;
  }

  @media screen and (max-width: ${smBreakPoint}) {
    text-align: center !important;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;

    &.widthLimit {
      max-width: 60%;
    }
  }

  @media screen and (max-width: ${xsBreakPoint}) {
    font-size: 15px;
  }

  &[data-ghost-text] {
    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: inherit;
    }

    &::before {
      content: attr(data-ghost-text);
      color: transparent;
    }

    @media screen and (max-width: ${smBreakPoint}) {
      & span {
        position: initial;
      }
      &::before {
        content: none;
        color: inherit;
      }
    }
  }
`

const SubDescription = styled.h4`
  line-height: 25px;
  margin: 0 0 25px 0;
  font-size: 16px;
  font-weight: normal;
  color: ${descriptionColor};
  position: relative;

  &.widthLimit {
    max-width: 295px;
  }

  @media screen and (max-width: ${smBreakPoint}) {
    text-align: center !important;

    &.widthLimit {
      max-width: 60%;
    }
  }

  @media screen and (max-width: ${xsBreakPoint}) {
    font-size: 14px;

    &.widthLimit {
      max-width: 90%;
    }
  }

  &[data-ghost-text] {
    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: inherit;
    }

    &::before {
      content: attr(data-ghost-text);
      color: transparent;
    }

    @media screen and (max-width: ${smBreakPoint}) {
      & span {
        position: initial;
      }
      &::before {
        content: none;
        color: inherit;
      }
    }
  }
`

const FeatureImgBox = styled.div`
  position: relative;
`

const FeatureImg = styled.img<{ scale?: number }>`
  &.widthLimit {
    max-width: 295px;
  }

  ${(props) =>
    typeof props.scale === 'number'
      ? css`
          transform: scale(${props.scale});
        `
      : ''}

  @media screen and (max-width: ${smBreakPoint}) {
    max-width: 60%;
  }
  @media screen and (max-width: ${xsBreakPoint}) {
    max-width: 90%;
  }
`

const FeatureImgFrame = styled(FeatureImg).attrs({ as: 'div' })<{ width: number, height: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`

type FeatureBoxProps = {
  center?: boolean
  marginCollapse?: boolean
  marginTop?: boolean
  marginBottom?: boolean
  marginTopStyle2?: boolean
  paddingInline?: boolean
}

const FeatureBox = styled.div<FeatureBoxProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  &:nth-child(2n + 1) {
    padding-right: 40px;
  }

  &:nth-child(2n) {
    padding-left: 40px;
  }

  ${(props) =>
    props.paddingInline
      ? css`
          padding-left: 30px;
          padding-right: 30px;
        `
      : ''}
  ${(props) =>
    props.marginTop
      ? css`
          margin-top: 57px;
        `
      : ''}
  ${(props) =>
    props.marginBottom
      ? css`
          margin-bottom: 0px !important;
        `
      : ''}
  ${(props) =>
    props.marginTopStyle2
      ? css`
          margin-top: 25px;
        `
      : ''}
  ${(props) =>
    props.center
      ? css`
          align-items: center;

          ${SubTitle} {
            text-align: center;
          }
          ${SubDescription} {
            text-align: center;
          }
        `
      : ''}

  @media screen and (max-width: ${smBreakPoint}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 0;
    margin-bottom: 20px;
    align-items: center;

    ${Button} {
      margin-right: 0;
    }

    ${(props) =>
      props.marginCollapse
        ? css`
            margin-top: -20px;

            @media screen and (max-width: ${smBreakPoint}) {
              margin-top: 20px;
            }
          `
        : ''}
  }
`

const TwoColumns = styled.div`
  ${sectionInlineSize};
  display: flex;
  margin-bottom: 50px;

  > * {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 0;
  }

  &:nth-child(2n) {
    flex-direction: row;
  }

  &:nth-child(2n + 1) {
    flex-direction: row-reverse;
  }

  &.reverse {
    &:nth-child(2n + 1) {
      flex-direction: row;
    }

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }
  }

  ${Description} {
    text-align: start;
    margin-bottom: 0;
  }

  @media screen and (max-width: ${smBreakPoint}) {
    flex-direction: column-reverse !important;

    padding-left: 0;
    padding-right: 0;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    ${FeatureBox} {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
`

const ThreeColumns = styled.div`
  ${sectionInlineSize};
  display: flex;

  > * {
    flex-basis: 33.3333333%;
    flex-grow: 1;
    flex-shrink: 0;
  }

  ${Description} {
    text-align: center;
  }

  @media screen and (max-width: ${smBreakPoint}) {
    flex-direction: column !important;

    padding-left: 0;
    padding-right: 0;
  }
`

const section2 = [
  {
    title: getZhCNtranscript(
      '可采取行动的目标',
      'Actionable Goals'
    ),
    content: getZhCNtranscript(
      '将梦想转化为清晰的结果。有目标地前进。',
      'Transform dreams into clear outcomes. Progress with purpose.'
    ),
  },
  {
    title: getZhCNtranscript(
      '可实现的里程碑',
      'Achievable Milestones'
    ),
    content: getZhCNtranscript(
      '把梦想分解成步骤。让不可能的事情成为可能。',
      'Break down dreams into steps. Make the impossible achievable.'
    ),
  },
  {
    title: getZhCNtranscript(
      '加速成功',
      'Adjustable Steps'
    ),
    content: getZhCNtranscript(
      '每一个奋进者的效率。每一个进步步骤的准确性。',
      'Efficiency in every endeavor. Precision in every progress step.'
    ),
  },
]

const section3 = [
  {
    title: getZhCNtranscript(
      '直观的设计',
      'Intuitive Design'
    ),
    content: getZhCNtranscript(
      '在苹果和谷歌商店免费。轻松地从 Elisi 开始。',
      'Free on Apple and Google app stores. Start effortlessly with Elisi.'
    ),
  },
  {
    title: getZhCNtranscript(
      '几分钟内从 Elisi 开始',
      'Start with Elisi in Minutes'
    ),
    content: getZhCNtranscript(
      '按照我们简单、用户友好的说明快速开始使用 Elisi。',
      'Quickly get started with Elisi by following our simple, user-friendly instructions.'
    ),
  },
  {
    title: getZhCNtranscript(
      '实际进展',
      'Real Progress'
    ),
    content: getZhCNtranscript(
      '与 Elisi 一起获得真知灼见并稳步进步。',
      'Gain insights and make steady progress with Elisi.'
    ),
  },
]

function getMaxContent<T extends Record<string, string>>(
  arr: T[],
  key: keyof T
) {
  return arr.reduce(
    (acc, curr) => (curr[key].length > acc.length ? curr[key] : acc),
    ''
  )
}

const ProductivityTrackingToolPage = React.memo(() => {
  const footerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    getShareInfo().then((r) => {
      if (r) {
        mixpanel.track('H5Page_enter', {
          source: r.sourceForMixpanel,
          account: r.userId,
        })
      }
    })
    mixpanel.track('Landing_enter', {
      source: typeof window === 'undefined' ? '' : window.location.href
    })
  }, [])

  const bonjourBannerRef = useRef<HTMLDivElement>(null)
  const bonjourS2Ref = useRef<HTMLDivElement>(null)
  const bonjourS4Ref = useRef<HTMLDivElement>(null)

  useEffect(
    () => {
      if (!bonjourBannerRef.current) {
        return
      }

      const lot = lottie.loadAnimation({
        container: bonjourBannerRef.current,
        renderer: 'svg',
        autoplay: true,
        loop: true,
        animationData: bonjourLottie,
      })

      return () => {
        lot.destroy()
      }
    },
    []
  )

  useEffect(
    () => {
      if (!bonjourS2Ref.current) {
        return
      }

      const lot = lottie.loadAnimation({
        container: bonjourS2Ref.current,
        renderer: 'svg',
        autoplay: true,
        loop: true,
        animationData: bonjourS2Lottie,
      })

      return () => {
        lot.destroy();
      }
    },
    []
  );

  useEffect(
    () => {
      if (!bonjourS4Ref.current) {
        return
      }

      const lot = lottie.loadAnimation({
        container: bonjourS4Ref.current,
        renderer: 'svg',
        autoplay: true,
        loop: true,
        animationData: bonjourS4Lottie,
      })

      return () => {
        lot.destroy();
      }
    },
    []
  );

  return (
    <Wrapper>
      <HeaderPlaceHolder />
      <HomeSection>
        <BonjourSection>
          <MainHeading>{transcript.mainHeading}</MainHeading>
          <Description className="left">{transcript.description0}</Description>
          <TryLinksWrapper>
            <Download text={transcript.freeDownload} />
            <Button
              onClick={sendGaForTryNow}
              accent="secondary"
              className="app-download-link"
            >
              {transcript.tryItOut}
            </Button>
          </TryLinksWrapper>
          
          {isZhCN() ? null : <a style={{ display: 'block' }} href="https://www.producthunt.com/posts/elisi?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-elisi" target="_blank">
            {/* <img 
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=477039&theme=light" 
              alt="Elisi - Mindful&#0032;Productivity&#0058;&#0032;Boost&#0032;Performance&#0032;with&#0032;AI&#0032;Coach | Product Hunt" 
              style={{ verticalAlign: 'middle' }}
              width="230" 
              height="auto"
            /> */}
            <PHIcon style={{verticalAlign: 'middle'}} />
          </a>}
          {/* <OtherLinkWrapper>
            <OtherLink onClick={anchorToFooter}>
              Other Download Links
              <SlideDownIcon />
            </OtherLink>
          </OtherLinkWrapper> */}
        </BonjourSection>
        <BonjourBackground ref={bonjourBannerRef}/>
      </HomeSection>
      <Section short>
        <SubHeading>{transcript.subHeading1}</SubHeading>
        <TwoColumns className="reverse">
          <FeatureBox>
            <Description>{transcript.description1}</Description>
          </FeatureBox>
          <FeatureBox marginBottom>
            <FeatureImg src={s1p1} width={506} height={281} />
          </FeatureBox>
        </TwoColumns>
      </Section>
      <Section>
        <SubHeading>{transcript.subHeading2}</SubHeading>
        <Description>{transcript.description2}</Description>
        <ThreeColumns>
          <FeatureBox center paddingInline>
            <FeatureImgBox>
              <FeatureImg src={s2p1} width={325} height={251} className="widthLimit" />
            </FeatureImgBox>
            <SubTitle data-ghost-text={getMaxContent(section2, 'title')}>
              <span>{section2[0].title}</span>
            </SubTitle>
            <SubDescription
              data-ghost-text={getMaxContent(section2, 'content')}
            >
              <span>{section2[0].content}</span>
            </SubDescription>
          </FeatureBox>
          <FeatureBox center paddingInline>
            <FeatureImgBox>
              <FeatureImgFrame width={325} height={251} className="widthLimit" />
              <BonjourS2Background ref={bonjourS2Ref}/>
            </FeatureImgBox>
            <SubTitle data-ghost-text={getMaxContent(section2, 'title')}>
              <span>{section2[1].title}</span>
            </SubTitle>
            <SubDescription
              data-ghost-text={getMaxContent(section2, 'content')}
            >
              <span>{section2[1].content}</span>
            </SubDescription>
          </FeatureBox>
          <FeatureBox center paddingInline>
            <FeatureImgBox>
              <FeatureImg src={s2p3} width={325} height={251} className="widthLimit" />
            </FeatureImgBox>
            <SubTitle data-ghost-text={getMaxContent(section2, 'title')}>
              <span>{section2[2].title}</span>
            </SubTitle>
            <SubDescription
              data-ghost-text={getMaxContent(section2, 'content')}
            >
              <span>{section2[2].content}</span>
            </SubDescription>
          </FeatureBox>
        </ThreeColumns>
        <FeatureBox center marginTopStyle2 marginCollapse>
          <Download small noMargin text={transcript.freeDownload} />
        </FeatureBox>
      </Section>
      <Section>
        <SubHeading>{transcript.subHeading3}</SubHeading>
        <Description>{transcript.description3}</Description>
        <ThreeColumns>
          <FeatureBox>
            <Step>
              <img src={step1} />
            </Step>
            <SubTitle
              data-ghost-text={getMaxContent(section3, 'title')}
              className="widthLimit"
            >
              <span>{section3[0].title}</span>
            </SubTitle>
            <SubDescription
              data-ghost-text={getMaxContent(section3, 'content')}
              className="widthLimit"
            >
              <span>{section3[0].content}</span>
            </SubDescription>
            <FeatureImg
              src={s3p1}
              width={325}
              height={251}
              className="widthLimit"
            />
          </FeatureBox>
          <FeatureBox>
            <Step>
              <img src={step2} />
            </Step>
            <SubTitle
              data-ghost-text={getMaxContent(section3, 'title')}
              className="widthLimit"
            >
              <span>{section3[1].title}</span>
            </SubTitle>
            <SubDescription
              data-ghost-text={getMaxContent(section3, 'content')}
              className="widthLimit"
            >
              <span>{section3[1].content}</span>
            </SubDescription>
            <FeatureImg
              src={s3p2}
              width={325}
              height={251}
              className="widthLimit"
            />
          </FeatureBox>
          <FeatureBox>
            <Step className="widthLimit">
              <img src={step3} />
            </Step>
            <SubTitle
              data-ghost-text={getMaxContent(section3, 'title')}
              className="widthLimit"
            >
              <span>{section3[2].title}</span>
            </SubTitle>
            <SubDescription
              data-ghost-text={getMaxContent(section3, 'content')}
              className="widthLimit"
            >
              <span>{section3[2].content}</span>
            </SubDescription>
            <FeatureImg
              src={s3p3}
              width={325}
              height={251}
              className="widthLimit"
            />
          </FeatureBox>
        </ThreeColumns>
        <FeatureBox center marginTop marginCollapse>
          <Download small noMargin text={transcript.freeDownload} />
        </FeatureBox>
      </Section>
      <Section>
        <SubHeading>{transcript.subHeading4}</SubHeading>
        <Description>{transcript.description4}</Description>
        <TwoColumns>
          <FeatureBox>
            <SubTitle>{transcript.subTitle41}</SubTitle>
            <SubDescription>{transcript.subDescription41}</SubDescription>
          </FeatureBox>
          <FeatureBox center>
            <FeatureImg src={s4p1} width={325} height={251} />
          </FeatureBox>
        </TwoColumns>
        <TwoColumns>
          <FeatureBox>
            <SubTitle>{transcript.subTitle42}</SubTitle>
            <SubDescription>{transcript.subDescription42}</SubDescription>
          </FeatureBox>
          <FeatureBox center>
            <FeatureImgBox>
              <FeatureImg src={s4p2} width={385} height={423} />
              <BonjourS4Background ref={bonjourS4Ref}/>
            </FeatureImgBox>
          </FeatureBox>
        </TwoColumns>
        <TwoColumns>
          <FeatureBox>
            <SubTitle>{transcript.subTitle43}</SubTitle>
            <SubDescription>{transcript.subDescription43}</SubDescription>
          </FeatureBox>
          <FeatureBox center>
            <FeatureImg src={s4p3} width={325} height={251} />
          </FeatureBox>
        </TwoColumns>
        <TwoColumns>
          <FeatureBox>
            <SubTitle>{transcript.subTitle44}</SubTitle>
            <SubDescription>{transcript.subDescription44}</SubDescription>
            <TryLinksWrapper2>
              <Button onClick={sendGaForTryNow} accent="primary">
                {transcript.tryItOut}
              </Button>
            </TryLinksWrapper2>
          </FeatureBox>
          <FeatureBox center>
            <FeatureImg src={s4p4} />
          </FeatureBox>
        </TwoColumns>
      </Section>
      <Section>
        <SubHeading>{transcript.subHeading5}</SubHeading>
        <Description />
        <TwoColumns>
          <FeatureBox>
            <Description>{transcript.description5}</Description>
          </FeatureBox>
          <FeatureBox center>
            <FeatureImg src={s5p1} style={{ width: '60%' }} />
          </FeatureBox>
        </TwoColumns>
      </Section>
      <Section>
        <SubHeading>{transcript.subHeading6}</SubHeading>
        <Description>{transcript.description6}</Description>
        <FeatureBox center marginTop>
          <Download small noMargin text={transcript.freeDownload} />
        </FeatureBox>
      </Section>
      <Footer ref={footerRef} />
    </Wrapper>
  )
})

export default ProductivityTrackingToolPage
